import { Link } from 'gatsby';
import React from 'react';
import { Button, ButtonConstants } from '../button';
import { Grid, Cell } from '../grid';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import './ARHero.scss';

const ARHero = ({
  showCta,
  showBackToHome = false,
  headerElement: HeaderElement = 'h1',
}) => {
  return (
    <div
      className='arModule'
      data-showcta={showCta}
      data-showbacktohome={showBackToHome}
    >
      {/* <div className='backgroundOverflow'> */}
      <div className='arBackground--mobile'>
        <div className='cloudContainer'>
          <img
            src='/img/arpage_cloud_1.png'
            className='arPageHeroElement arPageHeroElement__cloud1'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_2.png'
            className='arPageHeroElement arPageHeroElement__cloud2'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_3.png'
            className='arPageHeroElement arPageHeroElement__cloud3'
            alt=''
            role='presentation'
            aria-hidden='true'
          />

          <img
            src='/img/arpage_cloud_4.png'
            className='arPageHeroElement arPageHeroElement__cloud4'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_5.png'
            className='arPageHeroElement arPageHeroElement__cloud5'
            alt=''
            role='presentation'
            aria-hidden='true'
          />

          {/* Stars */}
          <img
            src='/img/Star1.png'
            className='arPageHeroElement arPageHeroElement__star1'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star2.png'
            className='arPageHeroElement arPageHeroElement__star2'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star3.png'
            className='arPageHeroElement arPageHeroElement__star3'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star4.png'
            className='arPageHeroElement arPageHeroElement__star4'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star5.png'
            className='arPageHeroElement arPageHeroElement__star5'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star6.png'
            className='arPageHeroElement arPageHeroElement__star6'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star7.png'
            className='arPageHeroElement arPageHeroElement__star7'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star8.png'
            className='arPageHeroElement arPageHeroElement__star8'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star9.png'
            className='arPageHeroElement arPageHeroElement__star9'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star10.png'
            className='arPageHeroElement arPageHeroElement__star10'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
        </div>

        <img
          src='/img/arpage_phone_left.png'
          className='arPageHeroElement phone_left'
          alt='A phone screenshot showing Emma riding a unicorn in augmented reality'
        />
        <img
          src='/img/arpage_phone_right.png'
          className='arPageHeroElement phone_right'
          alt='A phone screenshot showing how the Adventures with Zeee Bucks app can scan the side of the piggy bank to activate an augmented reality scene'
        />

        <img
          src='/img/arpage_lollipop_left.png'
          className='arPageHeroElement lollipop_left'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
        <img
          src='/img/arpage_tree.png'
          className='arPageHeroElement tree'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
        <img
          src='/img/arpage_lollipop_right_mobile.png'
          className='arPageHeroElement lollipop_right'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
        <img
          src='/img/arpage_coin_mobile.png'
          className='arPageHeroElement coin'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
      </div>

      <div className='arBackground--desktop'>
        <div className='cloudContainer'>
          <img
            src='/img/arpage_cloud_1.png'
            className='arPageHeroElement arPageHeroElement__cloud1'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_2.png'
            className='arPageHeroElement arPageHeroElement__cloud2'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_3.png'
            className='arPageHeroElement arPageHeroElement__cloud3'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_4.png'
            className='arPageHeroElement arPageHeroElement__cloud4'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/arpage_cloud_5.png'
            className='arPageHeroElement arPageHeroElement__cloud5'
            alt=''
            role='presentation'
            aria-hidden='true'
          />

          {/* Stars */}
          <img
            src='/img/Star1.png'
            className='arPageHeroElement arPageHeroElement__star1'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star2.png'
            className='arPageHeroElement arPageHeroElement__star2'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star3.png'
            className='arPageHeroElement arPageHeroElement__star3'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star4.png'
            className='arPageHeroElement arPageHeroElement__star4'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star5.png'
            className='arPageHeroElement arPageHeroElement__star5'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star6.png'
            className='arPageHeroElement arPageHeroElement__star6'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star7.png'
            className='arPageHeroElement arPageHeroElement__star7'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star8.png'
            className='arPageHeroElement arPageHeroElement__star8'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star9.png'
            className='arPageHeroElement arPageHeroElement__star9'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
          <img
            src='/img/Star10.png'
            className='arPageHeroElement arPageHeroElement__star10'
            alt=''
            role='presentation'
            aria-hidden='true'
          />
        </div>

        <img
          src='/img/arpage_phone_left.png'
          className='arPageHeroElement phone_left'
          alt='A phone screenshot showing Emma riding a unicorn in augmented reality'
          width='320'
          height='531'
        />
        <img
          src='/img/arpage_phone_right.png'
          className='arPageHeroElement phone_right'
          alt='A phone screenshot showing how the Adventures with Zeee Bucks app can scan the side of the piggy bank to activate an augmented reality scene'
          width='320'
          height='501'
        />

        <img
          src='/img/arpage_lollipop_left.png'
          className='arPageHeroElement lollipop_left'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
        <img
          src='/img/arpage_tree.png'
          className='arPageHeroElement tree'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
        <img
          src='/img/arpage_lollipop_right.png'
          className='arPageHeroElement lollipop_right'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
        <img
          src='/img/arpage_coin.png'
          className='arPageHeroElement coin'
          alt=''
          role='presentation'
          aria-hidden='true'
        />
      </div>
      {/* </div> */}

      <Grid>
        {showBackToHome && (
          <Cell small={12} collapse>
            <div className='breadcrumb'>
              <Link to={'/'} className='text-cta'>
                <span className='back-caret'></span>Back to Adventures with
                Money
              </Link>
            </div>
          </Cell>
        )}
        <Cell>
          <HeaderElement className='color-text-champagne text-xlarge-headline arModule-header'>
            adventures with zeee bucks in augmented reality
            <span className='color-text-grapefruit'>.</span>
          </HeaderElement>
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12} medium={8} large={4} className='arModule-body'>
          <p className='text-body color-text-champagne'>
            Explore Planet Zeee in AR with Emma, and learn all about earning,
            saving and spending. Download the app now to start!
          </p>
          <Button
            label='Explore Now'
            type={ButtonConstants.TYPE.INTERNAL}
            link='/ZeeeBucks'
            theme={ButtonConstants.THEMES.PRIMARY}
            className='arModule-button'
          />
          <div className='arModule-badges'>
            <OutboundLink
              href='https://apps.apple.com/us/app/adventure-with-zeee-bucks/id1551703703'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src='/img/app-badge-apple.svg'
                alt='Download the Adventures with Zeee Bucks app on the Apple App Store'
                width='300'
                height='100'
              />
            </OutboundLink>
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

export default ARHero;
