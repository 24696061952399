import React, { useState } from 'react';
import { Cell, Grid, GridContainer } from '../grid';

import './ARTrigger.scss';

const ButtonIcon = ({ itm, isActive, onClick }) => {
  return (
    <li key={itm.title} className='triggerIcon'>
      <button
        className='arTriggerButton'
        aria-expanded={isActive}
        aria-label={`Tap this ${itm.title} button to view the scannable ${itm.title} cube image`}
        onClick={e => {
          e.preventDefault();
          typeof window !== 'undefined' &&
            typeof window.gtag === 'function' &&
            window.gtag('event', 'button click', {
              category: 'scan and learn',
              label: itm.stylizedTitle,
            });
          onClick();
        }}
      >
        <div className='arTriggerButton_content'>
          <span
            className={`arTriggerButton_heading ${
              'align_' + itm.placement
            } color-text-plum`}
          >
            {itm.stylizedTitle}
          </span>
          <div className='arTriggerButton_images'>
            <img
              className='arTriggerButton_hover-image'
              src={itm.hover_icon}
              width='300'
              height='300'
              alt=''
              role='presentation'
              aria-hidden='true'
            />
            <img
              className='arTriggerButton_base-image'
              src={itm.icon}
              width='300'
              height='300'
              alt=''
              role='presentation'
              aria-hidden='true'
            />
          </div>
        </div>
      </button>
    </li>
  );
};

export default function ARTrigger({ triggerItems }) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <GridContainer className='ARTriggers color-background-champagne'>
      <Grid>
        <Cell small={12} large={5}>
          <h2>
            <span className='color-text-plum'>scan and</span>{' '}
            <span className='color-text-grapefruit'>learn!</span>
          </h2>
          <p className='text-body sub-header'>
            Open the app, point your phone at the images below, and initiate the
            experience.
          </p>
        </Cell>
      </Grid>
      <Grid className='triggerContainer'>
        <Cell className='activeTriggerItem' small={12} large={8} largePush={1}>
          <img
            src={triggerItems[activeIndex].url}
            width='1575'
            height='1575'
            alt={`Scan this ${triggerItems[activeIndex].title} image with the app on your phone to view the ${triggerItems[activeIndex].title} scene on Planet Zee`}
          />
        </Cell>
        <ul className='iconHolder'>
          {triggerItems.map((itm, idx) => {
            return (
              <ButtonIcon
                itm={itm}
                isActive={idx === activeIndex}
                key={idx + '_' + itm.title}
                onClick={() => setActiveIndex(idx)}
              />
            );
          })}
        </ul>
      </Grid>
    </GridContainer>
  );
}
