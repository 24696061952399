import React from 'react';
//
import './../css/index.scss';
import './ExplorePage.scss';
import Head from './../components/head/Head';
import { Cell, Grid, GridContainer } from './../components/grid';
import Nav from './../components/nav/Nav';
import { Button, ButtonConstants } from './../components/button';

import ARTrigger from './../components/arTrigger/ARTrigger';
import ARHero from '../components/arHero/ARHero';
import Footer from '../components/footer/Footer';

export default function ExplorePage({ pageContext }) {
  return (
    <div className='ExplorePage'>
      <Head {...pageContext.seoMetadata} />
      <GridContainer>
        <Grid>
          <Cell small={12} collapse>
            <Nav />
            <main className='body'>
              <ARHero
                showBleedBackground={true}
                showCta={false}
                showBackToHome={true}
              />
              <ARTrigger triggerItems={pageContext.triggerItems} />

              <Grid className='book-section'>
                <Cell small={12} large={12}>
                  <h2>
                    <span className='color-text-plum'>explore our</span>{' '}
                    <span className='color-text-grapefruit'>books.</span>
                  </h2>
                </Cell>

                <Cell small={12} large={6} collapse>
                  <Grid className='book-item'>
                    <Cell small={12} medium={6} collapse className='book-image'>
                      <img
                        src={'/img/Explore_Money_Tree_Book.jpg'}
                        alt='Planet Zeee and the Money Tree book'
                        width='665'
                        height='629'
                      />
                    </Cell>
                    <Cell small={12} medium={6} collapse>
                      <div className='book-title'>
                        <h3 className='color-text-plum'>
                          planet zeee and the money tree
                        </h3>
                        <Button
                          label={'Explore the Book'}
                          type={ButtonConstants.TYPE.INTERNAL}
                          link={'/planet-zeee-and-the-money-tree'}
                          ariaLabel={
                            'Explore Planet Zeee and the Money Tree book'
                          }
                        />
                      </div>
                    </Cell>
                  </Grid>
                </Cell>

                <Cell small={12} large={6} collapse>
                  <Grid className='book-item'>
                    <Cell small={12} medium={6} collapse className='book-image'>
                      <img
                        src={'/img/Explore_Emma_Book.jpg'}
                        alt='Emma and the Cosmo Phone book, new release'
                        width='665'
                        height='629'
                      />
                    </Cell>
                    <Cell small={12} medium={6} collapse>
                      <div className='book-title'>
                        <h3 className='color-text-plum'>
                          emma and the cosmo phone
                        </h3>
                        <Button
                          label={'Explore the Book'}
                          type={ButtonConstants.TYPE.INTERNAL}
                          link={'/emma-and-the-cosmo-phone'}
                          ariaLabel={'Explore Emma and the Cosmo Phone book'}
                        />
                      </div>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>

              <img
                src='http://ally.demdex.net/event?d_sid=21819596'
                width='0'
                height='0'
              />
            </main>
            <Footer className='ExploreFooter' showLegalese={true} />
          </Cell>
        </Grid>
      </GridContainer>
    </div>
  );
}
